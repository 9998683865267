/**
 * @description: 终端管理页面接口列表
 */
import request from './request'

// 获取终端列表
// export function get_terminal_list(data) {
//   return request({
//     url: "/terminal_api/terminals",
//     method: "post",
//     data
//   })
// }

// 日志上传
export function upload_terminal_log (data) {
  return request({
    url: '/terminal_api/upload_terminal_log',
    method: 'post',
    data
  })
}

// 添加终端
export function add_terminal (data) {
  return request({
    url: '/terminal_api/terminal_info',
    method: 'post',
    data
  })
}

// 修改终端
export function modify_terminal (data) {
  return request({
    url: '/terminal_api/terminal_info',
    method: 'put',
    data
  })
}

// 删除终端
export function delete_terminal (data) {
  return request({
    url: '/terminal_api/terminal_info',
    method: 'delete',
    data
  })
}

// 修改日志级别
export function modify_log_level (data) {
  return request({
    url: '/terminal_api/terminal_log_level',
    method: 'post',
    data
  })
}

// 查询日志级别
export function get_log_level (params) {
  return request({
    url: '/terminal_api/terminal_log_level',
    method: 'get',
    params
  })
}

/**
 * @description: 重启推流进程/设备
 * @param {
 *  terminal_id: 2,
 *  command: 1, 1: 重启推流进程 2: 重启推流设备
 * }
 */
export function rebort (data) {
  return request({
    url: '/terminal_api/terminal_command',
    method: 'post',
    data
  })
}

// 查询终端分组列表
export function get_terminal_group (data) {
  return request({
    url: '/terminal_api/terminal_group_list',
    method: 'post',
    data
  })
}
// 新增终端分组
export function add_terminal_group (data) {
  return request({
    url: '/terminal_api/terminal_group',
    method: 'post',
    data
  })
}

// 修改终端分组
export function update_terminal_group (data) {
  return request({
    url: '/terminal_api/terminal_group',
    method: 'put',
    data
  })
}
// 删除终端分组
export function delete_terminal_group (data) {
  return request({
    url: '/terminal_api/terminal_group',
    method: 'delete',
    data
  })
}

// 查询分组中的终端列表
export function get_terminals (data) {
  return request({
    url: '/terminal_api/terminals',
    method: 'post',
    data
  })
}

// 分配终端分组
export function assign_terminal_group (data) {
  return request({
    url: '/terminal_api/assign_terminal_group',
    method: 'post',
    data
  })
}
// 覆盖终端配置
export function cover_terminal_config (data) {
  return request({
    url: '/terminal_api/cover_terminal_config',
    method: 'post',
    data
  })
}
// 推流频道列表
export function publish_channel_list (data) {
  return request({
    url: '/terminal_api/publish_channel_list',
    method: 'post',
    data
  })
}
// 终端升级
export function terminal_update_seq (data) {
  return request({
    url: '/terminal_api/terminal_update_seq',
    method: 'post',
    data
  })
}
// 获取所有终端分组
export function get_all_terminals () {
  return request({
    url: '/terminal_api/all_terminal_group_list',
    method: 'get'
  })
}
// 根据推收流类型获取终端
export function get_terminals_by_type (params) {
  return request({
    url: '/terminal_api/terminals',
    method: 'get',
    params
  })
}

// 下载license文件
export function activate_file (params) {
  return request({
    url: '/terminal_api/activate_file',
    method: 'get',
    responseType: 'blob',
    params
  })
}

// 批量下载激活文件
export function download_activate_files (data) {
  return request({
    url: '/terminal_api/download_activate_files',
    method: 'post',
    responseType: 'blob',
    data
  })
}

// 获取终端错误消息
export function terminal_msg_list (params) {
  return request({
    url: '/terminal_api/terminal_msg_list',
    method: 'get',
    params
  })
}

// 允许下载授权文件
export function allow_download_license (data) {
  return request({
    url: '/terminal_api/allow_download_license',
    method: 'post',
    data
  })
}
// 清空终端错误消息
export function delete_terminal_msg_list (data) {
  return request({
    url: '/terminal_api/terminal_msg_list',
    method: 'delete',
    data
  })
}

