<template>
  <div class="wrapper">
    <div class="toolbar">
      <slot name="toolbar" />
    </div>
    <div class="table">
      <slot />
    </div>
    <div class="page">
      <slot name="page" />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang='scss' scoped>
.wrapper {
  padding-top: 20px;
  padding-right: 20px;
  display: flex;
  flex-flow: column;
  .table {
    margin-top: 20px;
  }
  .page {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }
}
</style>
