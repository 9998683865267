<template>
  <div>
    <TablePage>
      <template #toolbar>
        <el-button
          type="primary"
          @click="addGroup"
        >
          {{ $t("terminal.addGroup") }}
        </el-button>
      </template>
      <el-table
        border
        :data="terminalGroupList"
      >
        <el-table-column
          align="center"
          prop="terminal_group_name"
          :label="$t('terminal.groupName')"
        />
        <el-table-column
          align="center"
          prop="project_name"
          :label="$t('terminal.projectName')"
        >
          <template slot-scope="scope">
            {{ scope.row.project_name ? scope.row.project_name : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="psl_verbose_log"
          :label="$t('config.psl_verbose_log')"
        >
          <template slot-scope="scope">
            {{ scope.row.psl_verbose_log ? scope.row.psl_verbose_log : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="ptcp_log"
          :label="$t('config.ptcp_log')"
        >
          <template slot-scope="scope">
            {{ scope.row.ptcp_log ? scope.row.ptcp_log : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="terminal_num"
          :label="$t('terminal.terminal_num')"
        >
          <template slot-scope="scope">
            {{ scope.row.terminal_num ? scope.row.terminal_num : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('common.operate')"
          width="440"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="editTerminalGroup(scope.row)"
            >
              {{ $t("common.edit") }}
            </el-button>
            <el-button
              size="mini"
              @click="enterDetail(scope.row.id)"
            >
              {{ $t("common.detail") }}
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="coverTerminalConfig(scope.row.id)"
            >
              {{ $t("terminal.converConfig") }}
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="deleteGroup(scope.row.id)"
            >
              {{ $t("common.delete") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <template #page>
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </template>
    </TablePage>
    <!-- 添加/修改终端分组 -->
    <el-dialog
      :title="$t('terminal.group_msg')"
      :visible.sync="terminalGroupDiaolg"
      width="900px"
    >
      <el-form
        ref="terminalGroupRef"
        :model="terminalGroupMsg"
        inline
        label-width="180px"
        :rules="terminalGroupMsgRules"
      >
        <el-form-item
          :label="$t('terminal.groupName')"
          prop="terminal_group_name"
        >
          <el-input
            v-model="terminalGroupMsg.terminal_group_name"
            style="width:177px"
          />
        </el-form-item>
        <el-form-item
          :label="$t('config.psl_verbose_log')"
          prop="psl_verbose_log"
        >
          <el-select
            v-model="terminalGroupMsg.psl_verbose_log"
            :popper-append-to-body="false"
            style="width:177px"
            clearable
          >
            <el-option
              v-for="radio in pslVerboseLogList"
              :key="radio.option_value"
              :value="Number(radio.option_value)"
              :label="radio.option_label"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('config.ptcp_log')"
          prop="ptcp_log"
        >
          <el-select
            v-model="terminalGroupMsg.ptcp_log"
            :popper-append-to-body="false"
            style="width:177px"
            clearable
          >
            <el-option
              v-for="radio in ptcpLogList"
              :key="radio.option_value"
              :value="Number(radio.option_value)"
              :label="radio.option_label"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('terminal.projectName')"
          prop="project_name"
        >
          <el-select
            v-model="terminalGroupMsg.project_name"
            :popper-append-to-body="false"
            style="width:177px"
            clearable
          >
            <el-option
              v-for="item in projectOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="terminalGroupDiaolg = false">{{ $t("common.cancel") }}</el-button>
        <el-button
          type="primary"
          @click="confirmAddTerminalGroup"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TablePage from '@/components/TablePage.vue'
import { get_all_projectName } from '@/api/project'
import { get_sename_list } from '@/api/api.js'
import {
  get_terminal_group,
  add_terminal_group,
  update_terminal_group,
  get_terminals,
  delete_terminal_group,
  cover_terminal_config
} from '@/api/terminal'
import { mapMutations } from 'vuex'
export default {
  components: {
    TablePage
  },
  data () {
    return {
      terminalGroupList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      isAdd: true,
      logLevel: {},
      pslVerboseLogList: [],
      ptcpLogList: [],
      terminalGroupDiaolg: false,
      projectOptions: [],
      terminalGroupMsg: {
        terminal_group_name: '',
        project_name: '',
        ptcp_log: null,
        psl_verbose_log: null
      },
      terminalGroupMsgRules: {
        terminal_group_name: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
      }
    }
  },
  created () {
    this.pageSize = Number(localStorage.terminalGroupPageSize) || 10
    this.updateActiveMenu('')
    this.init()
  },
  methods: {
    ...mapMutations(['updateTerminalGroupID', 'updateActiveMenu']),
    // 进入该组终端详情页
    enterDetail (id) {
      this.updateTerminalGroupID(id)
      this.updateActiveMenu('/terminal/detail')
      this.$router.push('/terminal/detail')
    },
    async coverTerminalConfig (id) {
      const res = await cover_terminal_config({
        terminal_group_id: id
      })
      if (res.data.code === 0) {
        this.$message.success(this.$t('terminal.converSucc'))
      }
    },
    // 打开分组弹窗
    addGroup () {
      this.isAdd = true
      this.terminalGroupDiaolg = true
    },
    editTerminalGroup (row) {
      this.isAdd = false
      this.terminalGroupDiaolg = true
      const { terminal_group_name, project_name, ptcp_log, psl_verbose_log, id } = row
      this.terminalGroupMsg = { terminal_group_name, project_name, ptcp_log, psl_verbose_log, terminal_group_id: id }
    },
    // 确认添加终端分组
    confirmAddTerminalGroup () {
      this.$refs.terminalGroupRef.validate(async (valid) => {
        if (valid) {
          const loading = this.$globalLoading()
          if (this.isAdd) {
            const res = await add_terminal_group(this.terminalGroupMsg)
            if (res.data.code === 0) {
              this.$message.success(this.$t('common.addSuccess'))
              this.getTerminalGroupList()
              this.terminalGroupDiaolg = false
              // 添加成功之后清除输入信息
              this.terminalGroupMsg = {
                terminal_group_name: '',
                project_name: '',
                ptcp_log: null,
                psl_verbose_log: null
              }
            }
          } else {
            const res = await update_terminal_group(this.terminalGroupMsg)
            if (res.data.code === 0) {
              this.$message.success(this.$t('common.changeSuccess'))
              this.getTerminalGroupList()
              this.terminalGroupDiaolg = false
            }
          }
          loading.close()
        }
      })
    },
    // 删除当前终端分组
    async deleteGroup (id) {
      let count
      const params = {
        page_num: 0,
        page_size: 0,
        order_by: '',
        sort: 1,
        query_condition: {
          terminal_group_id: {
            type: 'int',
            value: id
          }
        }
      }
      const terminals = await get_terminals(params)
      if (terminals.data.code === 0) {
        count = terminals.data.data.terminal_count
      }
      let remind
      // 如果分组内存在终端，则提示分组内存在终端，是否删除？
      // 若分组内不存在终端，则提示分是否确认删除？
      if (count === 0) {
        remind = this.$confirm(this.$t('common.isConfirmDelete'), this.$t('common.remind'), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        })
      } else {
        remind = this.$confirm(this.$t('terminal.terminalRemind'), this.$t('common.remind'), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        })
      }
      remind.then(async () => {
        const res = await delete_terminal_group({ terminal_group_id: id })
        if (res.data.code === 0) {
          this.$message.success(this.$t('common.deleteSuccess'))
          this.getTerminalGroupList()
        }
      }).catch(() => {})
    },
    // 获取所有project名称列表
    async getAllProjectName () {
      const res = await get_all_projectName()
      if (res.data.code === 0) {
        this.projectOptions = res.data.data
      }
    },
    // 获取终端分组列表
    async getTerminalGroupList () {
      const params = {
        page_num: this.currentPage,
        page_size: this.pageSize,
        order_by: 'id',
        sort: 1,
        query_condition: {}
      }
      const res = await get_terminal_group(params)
      if (res.data.code === 0) {
        this.terminalGroupList = res.data.data.terminals
        this.total = res.data.data.terminal_count
      }
    },
    // 修改pageSize
    handleSizeChange (size) {
      this.pageSize = size
      localStorage.terminalGroupPageSize = size
      this.getTerminalGroupList()
    },
    // 修改当前页码
    handleCurrentChange (current_page) {
      this.currentPage = current_page
      this.getTerminalGroupList()
    },
    // 获取下拉参数
    async getSelectList () {
      const getPslVerboseLogList = get_sename_list({ select_name: 'psl_verbose_log' })
      const getPtcpLogList = get_sename_list({ select_name: 'ptcp_log' })
      const res1 = await getPslVerboseLogList
      const res2 = await getPtcpLogList
      this.pslVerboseLogList = res1.data.data
      this.ptcpLogList = res2.data.data
    },
    // 初始化
    init () {
      this.getAllProjectName()
      this.getTerminalGroupList()
      this.getSelectList()
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
